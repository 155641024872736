<template>
  <section id="benefit" class="benefit section" :class="{ 'valentine_day': isNowBefore() }">
    <div class="benefit__inner section__inner">
      <div class="benefit__img section__img">
        <div class="gradient"></div>
        <img src="../images/bacground/benefit.jpg" alt="" />
      </div>
      <div class="benefit__content section__content">
        <div class="benefit__content-inner">
          <h2 class="benefit__title section__title">
            Выгодный кредит на HYUNDAI<br />
            Оставьте заявку, чтобы рассчитать кредит
          </h2>
          <h3 class="benefit__subtitle">
            <!-- 95% одобрения <br />
            Оформление за 1 час -->
          </h3>
        </div>
        <div class="benefit__form">
          <callback-input
            v-bind="$attrs"
            :form_class="'benefit__form'"
            :focusOnMount="false"
            :data="{form:'benefit',type:'sale',}"
          >
            {{ data?.button ? data?.button : "Узнать цену по акции" }}
          </callback-input>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Mixin from "../common/mixin";
import CallbackInput from "./callback-form/callback-input";
export default {
  props:['data'],
  mixins: [Mixin],
  components: { CallbackInput },
};
</script>

<style lang="sass" scoped>
.benefit
    &__content
        display: flex
        justify-content: center
        flex-direction: column
        padding-left: 40px
        flex-direction: column
    &__subtitle
        font-size: 22px
        font-weight: 400
        margin-bottom: 63px
        margin-top: 15px
    &__img
      position: relative
@media screen and (max-width: 1174px)
  .benefit__content
    padding: 15px 0px  15px 25px


@media screen and (max-width: 939px)
    .benefit__form
      margin-top: 45px
    .benefit__content-inner
      position: absolute
      color: #fff
      top: calc( 100vw - 57vw )
    .benefit__inner
        display: flex
        flex-direction: column
        position: relative
    .benefit__title
      text-align: center
    .benefit__subtitle
        margin: 0px
        margin-top: 15px
    .benefit__content-inner
      width: 100%
    .benefit__form
      margin: 45px auto 0
@media screen and (max-width: 540px)
  .benefit__content-inner
      bottom: 213px
      top: auto
  .benefit__content
    padding: 0 10px
  .benefit__title
        font-size: 18px
        line-height: 22px
  .benefit__subtitle
        font-size: 18px
        line-height: 22px
        margin-top: 5px
  .benefit__form
      margin-top: 24px
  .benefit
    padding-bottom: 50px
</style>

<style lang="scss" scoped>
// Удалить после 15.02.2025
@use '@/styles/constants.scss' as *;

.valentine_day {
  background: url('../images/bacground/v_d_benefit.jpg') no-repeat center/cover;
}

@media (max-width: 800px) {
  .valentine_day {
    background: url('../images/bacground/v_d_benefit.jpg') no-repeat right/cover;
  }
}
// Удалить после 15.02.2025
</style>
